<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="18" fill="#6941C6" stroke="white" stroke-width="1.5" />
    <path
      d="M11.5 13.6429C11.5 12.6327 11.5 12.1276 11.8138 11.8138C12.1276 11.5 12.6327 11.5 13.6429 11.5H24.3571C25.3673 11.5 25.8724 11.5 26.1862 11.8138C26.5 12.1276 26.5 12.6327 26.5 13.6429V24.3571C26.5 25.3673 26.5 25.8724 26.1862 26.1862C25.8724 26.5 25.3673 26.5 24.3571 26.5H13.6429C12.6327 26.5 12.1276 26.5 11.8138 26.1862C11.5 25.8724 11.5 25.3673 11.5 24.3571V13.6429Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M17.509 10.0543C18.2119 9.35142 18.5633 9 19 9C19.4367 9 19.7881 9.35142 20.491 10.0543L27.9457 17.509C28.6486 18.2119 29 18.5633 29 19C29 19.4367 28.6486 19.7881 27.9457 20.491L20.491 27.9457C19.7881 28.6486 19.4367 29 19 29C18.5633 29 18.2119 28.6486 17.509 27.9457L10.0543 20.491C9.35142 19.7881 9 19.4367 9 19C9 18.5633 9.35142 18.2119 10.0543 17.509L17.509 10.0543Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <circle cx="19" cy="19" r="2" stroke="white" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '38',
    },
    width: {
      type: String,
      default: '38',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
